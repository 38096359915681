import { createGlobalStyle, css } from 'styled-components';
import DetectTouch from '/lib/detect-touch';

import colors from './colors';
import effects from './effects';
import {
    responsiveDistanceStyles,
    staticDistanceStyles,
} from './distanceStyles';
import responsiveHelpers from './responsiveHelpers';

export const headingSizes = [
    {
        name: 'xxl',
        desktop: { fs: 110, lh: 120 },
        tablet: { fs: 72, lh: 80 },
        mobile: { fs: 42, lh: 48 },
    },
    {
        name: 'xl',
        desktop: { fs: 72, lh: 80 },
        tablet: { fs: 60, lh: 62 },
        mobile: { fs: 42, lh: 48 },
    },
    {
        name: 'large',
        desktop: { fs: 42, lh: 46 },
        tablet: { fs: 36, lh: 42 },
        mobile: { fs: 32, lh: 40 },
    },
    {
        name: 'medium',
        desktop: { fs: 28, lh: 36 },
        tablet: { fs: 24, lh: 30 },
        mobile: { fs: 20, lh: 26 },
    },
    {
        name: 'small',
        desktop: { fs: 24, lh: 28 },
        tablet: { fs: 20, lh: 28 },
        mobile: { fs: 17, lh: 22 },
    },
];

const headingClasses = () =>
    headingSizes
        .map(
            (size) => `
    h1.${size.name}, h2.${size.name}, h3.${size.name}, h4.${size.name}, h5.${size.name}, h6.${size.name}, .heading-${size.name} {
        font-family: gellix-bold;
        color: ${colors.black};
        position:relative;
        font-size: ${size.desktop.fs}px;
        line-height: ${size.desktop.lh}px;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: ${size.tablet.fs}px;
            line-height: ${size.tablet.lh}px;
        }
        @media(max-width: 767px) {
            font-size: ${size.mobile.fs}px;
            line-height: ${size.mobile.lh}px;
            white-space: initial!important;
        }
    }
`
        )
        .join(' ');

export const paragraphSizes = [
    {
        name: 'large',
        desktop: { fs: 28, lh: 38 },
        tablet: { fs: 24, lh: 34 },
        mobile: { fs: 20, lh: 28 },
    },
    {
        name: 'medium',
        desktop: { fs: 24, lh: 36 },
        tablet: { fs: 20, lh: 32 },
        mobile: { fs: 17, lh: 26 },
    },
    {
        name: 'small',
        desktop: { fs: 20, lh: 28 },
        tablet: { fs: 17, lh: 26 },
        mobile: { fs: 16, lh: 23 },
    },
    {
        name: 'smaller',
        desktop: { fs: 17, lh: 26 },
        tablet: { fs: 17, lh: 26 },
        mobile: { fs: 16, lh: 23 },
    },
    {
        name: 'nano',
        desktop: { fs: 15, lh: 21 },
        tablet: { fs: 12, lh: 17 },
        mobile: { fs: 12, lh: 17 },
    },
    {
        name: 'smallest',
        desktop: { fs: 13, lh: 20 },
        tablet: { fs: 13, lh: 20 },
        mobile: { fs: 13, lh: 20 },
    },
];

export const getParagraphSize = (size) =>
    paragraphSizes.find((s) => s.name === size);

const paragraphClasses = () =>
    paragraphSizes
        .map(
            (size) => `
    p.${size.name},.paragraph-${size.name}, .rich-text.${size.name} p, .rich-text.${size.name} li {
        color: ${colors.black};
        position: relative;
        font-family: "gellix-semi";
        font-size: ${size.desktop.fs}px;
        line-height: ${size.desktop.lh}px;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: ${size.tablet.fs}px;
            line-height: ${size.tablet.lh}px;
        }
        @media(max-width: 767px) {
            font-size: ${size.mobile.fs}px;
            line-height: ${size.mobile.lh}px;
        }
    }
`
        )
        .join(' ');

const isTouch = DetectTouch();

const resetStyles = css`
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    } /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol,
    ul {
        list-style: none;
    }
    blockquote,
    q {
        quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
`;

export const miscGlobalStyles = css`
    p a,
    span a {
        color: ${colors.purple}!important;
        &:hover {
            color: ${colors.orange}!important;
        }
    }

    p strong {
        color: ${colors.black};
        font-family: 'gellix-bold';
    }

    .light,
    h1.light,
    h2.light,
    h3.light,
    h4.light,
    h5.light,
    h6.light,
    p.light {
        font-family: 'gellix-medium';
    }

    .semi {
        font-family: 'gellix-semi';
    }

    .bold,
    strong {
        font-family: 'gellix-bold';
    }

    .weak,
    p.weak {
        font-family: 'gellix-medium';
        color: ${colors.grey6};
    }

    .purple {
        color: ${colors.purple};
    }

    .text-align-center {
        text-align: center;
    }

    .align-right,
    .text-align-right {
        text-align: right;
    }

    .text-align-left {
        text-align: left;
    }

    .centered,
    .m-x-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .block {
        display: block;
    }

    .oh {
        overflow: hidden;
    }

    .shadow {
        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06),
            0 2px 6px rgba(31, 26, 34, 0.06);
    }

    .overlay {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            display: block;
        }
    }

    * {
        box-sizing: border-box;
    }

    html {
        max-width: 100%;
        overflow-x: hidden;
        -webkit-text-size-adjust: none;
        touch-action: manipulation;
    }

    body {
        font-family: 'gellix-medium', 'HelveticaNeue-Light',
            'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
            'Lucida Grande', sans-serif;
        font-size: 16px;
        line-height: 1.3;
        background: #fff;
        color: ${colors.black};
        min-height: 100dvh;
        font-display: fallback;
        max-width: 100%;
        overflow: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
    }

    body > canvas {
        display: none;
    }

    :focus {
        outline: none;
    }

    #__next {
        //min-height: calc(100vh - 77px);
        min-height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
            //overflow:hidden;
        }
    }

    a,
    a:hover,
    a:visited,
    a:active {
        text-decoration: none;
        color: ${colors.black};
        transition: 0.15s ease-in-out;
        &.orange {
            color: ${colors.orange};
            ${!isTouch &&
            css`
                &:hover {
                    color: pink;
                }
            `}
        }
    }

    ul.list,
    ol.list {
        padding-left: 20px;
        li {
            list-style: disc;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    ol.list {
        li {
            list-style: decimal;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    button {
        cursor: pointer;
        margin: 0;
        padding: 0;
        outline: 0;
        box-shadow: none;
        background: transparent;
        border: none;
        line-height: 1;
        font-family: 'gellix-medium';
        font-weight: 400;
    }

    * {
        //outline: 1px dotted grey;
    }

    select {
        display: block;
        font-size: 14px !important;
        font-weight: normal;
        color: ${colors.black};
        line-height: 1;
        padding: 8px 30px 8px 12px;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        border: 1px solid #d8dce2;
        box-shadow: none !important;
        border-radius: 5px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: rgba(255, 255, 255, 0.6);
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMSA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1NSAoNzgwNzYpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPlRyaWFuZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkRlc2t0b3AtSEQtQ29weS0yMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYzMy4wMDAwMDAsIC0xMDM4LjAwMDAwMCkiIGZpbGw9IiMwMDdCRkYiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iVHJpYW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYzOC41MDAwMDAsIDEwNDIuMDAwMDAwKSByb3RhdGUoLTE4MC4wMDAwMDApIHRyYW5zbGF0ZSgtNjM4LjUwMDAwMCwgLTEwNDIuMDAwMDAwKSAiIHBvaW50cz0iNjM4LjUgMTAzOCA2NDQgMTA0NiA2MzMgMTA0NiI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
        background-repeat: no-repeat, repeat;
        background-position: right 0.8em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
        height: 36px;
        transition: all 0.2s ease-in-out;
        outline: none;
        font-family: 'gellix-medium';
    }
    select::-ms-expand {
        display: none;
    }
    ${!isTouch &&
    css`
        select:hover {
            border-color: #888;
        }
    `}
    select:focus {
        box-shadow: none;
        outline: none;
    }
    select option {
        font-weight: normal;
    }

    input,
    textarea {
        width: 100%;
        padding: 8px 12px;
        height: 36px;
        font-size: 14px;
        background: rgba(255, 255, 255, 0.6);
        outline: none;
        box-shadow: none;
        border: 1px solid #d8dce2;
        color: ${colors.black};
        border-radius: 5px;
        line-height: 1;
        transition: all 0.2s ease-in-out;
        font-family: 'gellix-medium';

        background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0),
            hsla(0, 0%, 100%, 0)
        ) !important;

        ${!isTouch &&
        css`
            &:hover:not(:disabled) {
                //border-color: ${colors.grey6};
            }
        `}
        &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill, &:-webkit-autofill-strong-password, &:-webkit-autofill-strong-password-viewable {
            box-shadow: 0 6px 24px rgba(0, 0, 0, 0.06),
                0 2px 6px rgba(31, 26, 34, 0.06), 0 0 0 50px white inset !important;
            background-image: -webkit-linear-gradient(
                hsla(0, 0%, 100%, 0),
                hsla(0, 0%, 100%, 0)
            ) !important;
        }
    }

    input[type='text'],
    textarea {
        &:read-only {
            opacity: 0.6;
        }
    }

    textarea {
        min-height: 100px;
    }

    label:has(input.checkbox:not(:checked)) {
        &:hover:not(:active) {
            input.checkbox {
                border-color: #e7e7e8;
            }
        }
        &:active {
            input.checkbox {
                border-color: #dedee0;
            }
        }
    }

    input.checkbox {
        display: inline-block;
        padding: 0;
        margin: 0 16px 0 0;
        -webkit-appearance: none;
        position: relative;
        cursor: pointer;
        background: transparent;
        outline: none;
        box-shadow: none;
        transition: border-color 0.2s ease-in-out;

        height: 24px;
        min-height: 24px;
        width: 24px;
        min-width: 24px;
        border: 3px solid ${colors.grey2};
        border-radius: 4px;

        &.error {
            border: 2px solid #ff6400;
        }

        &:checked {
            border: 3px solid #ff6400;
            background: #ff6400;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjA0OTM4IDQuMjgwOTdMNS4zMzM1NiA3LjU2MzU0TDExLjk0ODcgMC45NDcyNjZMMTMuNzE4IDIuNzE2OTRMNS4zMzM1NiAxMS4wOTkyTDAuMjgyMjI3IDYuMDQ4MzdMMi4wNDkzOCA0LjI4MDk3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) !important;
            background-position: top 4px right 2px;
            background-repeat: no-repeat;
            transition: none;

            &:disabled {
                background-color: #ebebeb;
                border-color: #ebebeb;
            }
        }

        &.error {
            border: 3px solid #ff6400;
        }
    }

    .intercom-launcher-frame {
        z-index: 999 !important;
    }

    body.is-configurator {
        #itx-chat-frame {
            bottom: 120px !important;
            @media (max-width: 767px) {
                bottom: 80px !important;
            }
        }
    }

    body.is-videoMeeting,
    body.is-touring,
    body.is-ELECTRICIAN,
    body.is-CONTRACTOR_ADMIN,
    body.is-GLOBAL_ADMIN,
    body.is-CASEWORKER,
    body.is-STORE_EMPLOYEE {
        #itx-chat-frame {
            display: none !important;
        }
    }

    #itx-chat-frame {
        top: initial !important;
    }

    .illustration {
        max-width: 600px;
        width: 100%;
    }

    .link-purple {
        color: #5e43fd !important;
        &:hover {
            color: #ff6400 !important;
        }
    }

    .link-orange {
        color: #ff6400 !important;
        &:hover {
            color: #5e43fd !important;
        }
    }

    .orange {
        color: #ff6400;
    }

    .bg-orange-light {
        background: #fedccd;
    }

    .bg-green-light {
        background: #dcfcd6;
    }

    .no-br-on-mobile {
        @media (max-width: 767px) {
            br {
                display: none;
            }
        }
    }

    .mw-220 {
        min-width: 220px;
    }
    .btn-center-x {
        display: flex !important;
        margin-left: auto;
        margin-right: auto;
    }

    .show-on-mobile {
        display: none;
        @media (max-width: 767px) {
            display: block;
        }
    }

    .flex {
        display: flex;
    }
    .inline-flex {
        display: inline-flex;
    }
    .flex-1 {
        flex: 1;
    }
    .bgd-white {
        background: white;
    }
    .flex-1-d {
        @media (min-width: 767px) {
            flex: 1;
        }
    }
    .flex-center-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .flex-center-center-y {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .wrap {
        flex-wrap: wrap;
    }
    .direction-column {
        flex-direction: column;
    }
    .justify-center {
        justify-content: center;
    }
    .justify-end {
        justify-content: flex-end;
    }
    .align-center {
        align-items: center;
    }
    .align-flex-end {
        align-items: flex-end;
    }
    .justify-space-between {
        justify-content: space-between;
    }
    .align-space-between {
        align-items: space-between;
    }
    .full-width {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .full-width-on-mobile {
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    .children-full-width-on-mobile {
        > * {
            @media (max-width: 767px) {
                width: 100% !important;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .red-border-on-hover {
        &:hover {
            border: 3px solid #ff6400 !important;
        }
    }
    .hide-on-desktop {
        @media (min-width: 1025px) {
            display: none;
        }
    }
    .hide-on-tablet {
        @media (min-width: 768px) and (max-width: 1024px) {
            display: none;
        }
    }
    .hide-on-mobile {
        @media (max-width: 767px) {
            display: none;
        }
    }
    ._hide-on-tablet {
        @media (min-width: 650px) and (max-width: 1024px) {
            display: none;
        }
    }
    ._hide-on-mobile {
        @media (max-width: 649px) {
            display: none;
        }
    }
    .mh-100vh {
        min-height: 100vh;
    }
    .relative {
        position: relative;
    }
    .inline-block {
        display: inline-block;
    }
    .inline-flex {
        display: inline-flex;
    }
    .color-grey {
        color: ${colors.grey6};
    }
    .description {
        font-size: 15px;
        line-height: 23px;
        color: ${colors.grey6};
        font-family: 'gellix-medium';
    }

    .spoton-toast {
        > div {
            width: 100%;
            max-width: 1120px;
            margin: 0 auto;
            > div {
                width: 100%;
            }
        }
        > div:last-child {
            flex: initial;
        }
    }

    .controlls-on-hover {
        position: relative;
        .item-controlls {
            opacity: 0;
            visibility: hidden;
        }
        &:hover {
            .item-controlls {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .br {
        border-radius: 12px;
    }
    .rounded {
        border-radius: 100px;
    }

    .clickable {
        cursor: pointer;
    }

    .underlined {
        text-decoration: underline;
    }

    .clickable-text {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .hover-shadow {
        ${effects.shadow};
        transition: box-shadow 0.2s ease-in-out;
        &:hover {
            ${effects.shadowHover};
        }
    }

    .editable {
        transition: outline 0.2s ease-in-out;
        outline: 1px dashed transparent;
        &:hover {
            outline: 1px dashed #95939f;
        }
    }

    .rich-text {
        font-family: 'gellix-medium', 'HelveticaNeue-Light',
            'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
            'Lucida Grande', sans-serif;
        color: ${colors.black};
        font-size: 24px;
        line-height: 36px;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 20px;
            line-height: 32px;
        }
        @media (max-width: 767px) {
            font-size: 17px;
            line-height: 26px;
        }

        .rich-img-float-left {
            float: left;
            margin-right: 20px;
            img {
                margin-bottom: 0px !important;
                @media (max-width: 767px) {
                    margin-bottom: 20px !important;
                }
            }
        }

        .rich-img-float-right {
            float: right;
            margin-left: 20px;
            img {
                margin-bottom: 0px !important;
                @media (max-width: 767px) {
                    margin-bottom: 20px !important;
                }
            }
        }

        a {
            color: ${colors.purple}!important;
            &:hover {
                color: ${colors.orange}!important;
            }
        }
        h1 {
            font-family: gellix-bold;
            color: ${colors.black};
            position: relative;
            font-size: 110px;
            line-height: 120px;
            margin-bottom: 32px;
            margin-top: -12px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 72px;
                line-height: 80px;
                margin-bottom: 24px;
                margin-top: -8px;
            }
            @media (max-width: 767px) {
                margin-top: -4px;
                font-size: 42px;
                line-height: 48px;
                margin-bottom: 16px;
            }
        }
        h2 {
            font-family: gellix-bold;
            color: ${colors.black};
            position: relative;
            font-size: 72px;
            line-height: 80px;
            margin-bottom: 32px;
            margin-top: -12px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 60px;
                line-height: 62px;
                margin-bottom: 24px;
                margin-top: -8px;
            }
            @media (max-width: 767px) {
                margin-top: -4px;
                font-size: 42px;
                line-height: 48px;
                margin-bottom: 16px;
            }
        }
        h3 {
            font-family: gellix-bold;
            color: ${colors.black};
            position: relative;
            font-size: 42px;
            line-height: 46px;
            margin-bottom: 24px;
            margin-top: -8px;
            @media (min-width: 768px) and (max-width: 1024px) {
                margin-top: -4px;
                font-size: 36px;
                line-height: 42px;
            }
            @media (max-width: 767px) {
                margin-top: -2px;
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 16px;
            }
        }
        h4 {
            font-family: gellix-bold;
            color: ${colors.black};
            position: relative;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 20px;
            margin-top: -6px;
            @media (min-width: 768px) and (max-width: 1024px) {
                margin-top: -2px;
                font-size: 24px;
                line-height: 30px;
            }
            @media (max-width: 767px) {
                margin-top: 0px;
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 16px;
            }
        }
        p,
        div[data-block='true'] {
            margin-bottom: 20px;
            @media (max-width: 767px) {
                margin-bottom: 16px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
        p:empty,
        h2:empty,
        h3:empty,
        h4:empty,
        h5:empty {
            &:before {
                content: '.';
                color: transparent;
            }
        }
        strong {
            font-family: 'gellix-bold';
            color: inherit;
        }
        em {
            font-style: italic;
        }
        ul,
        ol {
            margin-bottom: 22px;
            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
            &:last-child {
                margin-bottom: 5px;
            }
        }
        ul li,
        ol li {
            list-style: disc;
            margin-bottom: 10px;
            margin-left: 30px;
            font-size: 24px;
            line-height: 36px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 20px;
                line-height: 32px;
            }
            @media (max-width: 767px) {
                font-size: 17px;
                line-height: 26px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        ol {
            counter-reset: number;
            li {
                list-style: none !important;
                position: relative;
                counter-increment: number;
                &::after {
                    content: counter(number);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 1;
                    font-size: 14px;
                    font-family: 'gellix-semi';
                    position: absolute;
                    padding-bottom: 1px;
                    top: 8px;
                    left: -30px;
                    background: ${colors.purple};
                    color: #fff;
                    border-radius: 12px;
                    width: 24px;
                    height: 23px;
                    @media (min-width: 768px) and (max-width: 1024px) {
                        top: 6px;
                    }
                    @media (max-width: 767px) {
                        top: 4px;
                    }
                }
                &::before,
                &::marker {
                    display: none !important;
                }
            }
        }

        &.small {
            p,
            ul li,
            ol li {
                font-family: 'gellix-medium';
            }
            p {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            ul li {
                margin-bottom: 5px;
            }
            ol li {
                margin-bottom: 7px;
                &::after {
                    top: 4px;

                    @media (max-width: 767px) {
                        top: 2px;
                    }
                }
            }
        }

        &.smaller {
            p,
            ul li,
            ol li {
                font-family: 'gellix-medium';
            }
            p {
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            ul li {
                margin-bottom: 5px;
            }
            ol li {
                margin-bottom: 7px;
                &::after {
                    top: 2px;
                    font-size: 13px;

                    @media (max-width: 767px) {
                        top: 0;
                    }
                }
            }
        }

        &--small {
            p,
            ul li,
            ol li {
                font-size: 17px;
                line-height: 26px;
                font-weight: 600;
            }
            ol li::after {
                top: 3px;
            }
        }
    }

    .legacy-cmodule {
        border: 1px dashed red;
        background: rgba(255, 0, 0, 0.05);
        margin: 10px 0;
        position: relative;
        &:before {
            content: 'Legacy module';
            position: absolute;
            background: red;
            color: white;
            top: 0;
            left: 0;
            display: block;
            z-index: 10;
            padding: 4px 8px 6px;
        }
    }
`;

export default createGlobalStyle`
    ${resetStyles};

    ${responsiveDistanceStyles};

    ${staticDistanceStyles};

    ${responsiveHelpers};

    ${headingClasses()}

    ${paragraphClasses()}

    ${miscGlobalStyles}
`;
